import {AgoraWindowAction} from "../redux/types";
import {AuthUser} from "../../auth/authTypes";
import {agoraEndpoints} from "@sense-os/goalie-js/dist/commons";
import {NDCallType, NDParam} from "@sense-os/goalie-js";

/**
 * Get Agora meeting url to be rendered in iframe
 */
export function getAgoraMeetingUrl(
	meetingAction: AgoraWindowAction,
	localUser: AuthUser,
	isVideoEnabled: boolean,
	remoteUserId?: number,
	roomId?: string,
): string {
	if (!meetingAction || !localUser || isVideoEnabled === undefined) {
		return;
	}

	let meetingUrl: string = "";
	const localNameParam: string = `local_name=${localUser?.fullName}`;
	const selectedCallType: string = isVideoEnabled ? NDCallType.VIDEO : NDCallType.AUDIO;
	const callTypeParam: string = [NDParam.CALL_TYPE, selectedCallType].join("=");

	switch (meetingAction) {
		case AgoraWindowAction.CREATE:
			/**
			 * Example URL to be created
			 * https://meeting-ag.vercel.app/create/NDRoom?public_remote_user_id=112233&local_name=John
			 */
			const roomName: string = "NiceDay Meeting";
			const remoteUserIdParam: string = [NDParam.PUBLIC_REMOTE_USER_ID, remoteUserId].join("=");
			const queryParams: string = [remoteUserIdParam, callTypeParam, localNameParam].join("&");
			meetingUrl = agoraEndpoints.createMeeting(roomName, queryParams);
			break;

		case AgoraWindowAction.JOIN:
			/**
			 * Example URL to be created
			 * https://meeting-ag.vercel.app/join/b8a08ced-3f7c-4330-bfd2-714a81f34832?local_name=John
			 */
			const callTypeAndLocalName: string = [callTypeParam, localNameParam].join("&");
			meetingUrl = agoraEndpoints.joinMeeting(roomId, callTypeAndLocalName);
			break;
	}

	return meetingUrl;
}

/**
 * Extract roomId from message text for join meeting room
 */
export const extractRoomIdFromText = (text: string): string => {
	if (!text) {
		return;
	}

	const matches = text?.match(/\bhttps?:\/\/\S+/gi);
	const splittedQuery: string[] = matches?.toString().split("?");

	return splittedQuery[0]?.toString().split("/").pop();
};

/**
 * Checking if meeting url contains "video" value from "call_type" param
 */
export const isVideoCallType = (text: string): boolean => {
	return text?.includes([NDParam.CALL_TYPE, NDCallType.VIDEO].join("="));
};
